<template>
  <v-row no-gutters>
    <v-col>
      <v-row align="center" justify="center">
        <v-col cols="8" xl="4" md="6" sm="8">
          <ValidationObserver ref="form" v-slot="{ handleSubmit }">
            <v-form class="mt-5" @submit.prevent="handleSubmit(onSubmit)">

              <ValidationProvider name="detail" v-slot="{ errors }">
                <template v-if="errors.length > 0">
                 <v-alert
                    border="left"
                    colored-border
                    type="warning"
                    elevation="2"
                  >
                   <span v-for="(error, index) in errors" :key="index">{{ error }}</span>
                 </v-alert>
                </template>
              </ValidationProvider>

              <ValidationProvider name="email" rules="required|email" v-slot="{ errors }">
                 <v-text-field
                    label="Email"
                    name="email"
                    v-model="form.email"
                    prepend-icon="mdi-email"
                    type="text"
                    :error-messages="errors"
                    outlined/>
              </ValidationProvider>

              <ValidationProvider name="password" rules="required" v-slot="{ errors }">
                <v-text-field
                    :error-messages="errors"
                    id="password"
                    label="Password"
                    name="password"
                    v-model="form.password"
                    prepend-icon="mdi-lock"
                    type="password"
                    outlined/>
              </ValidationProvider>

              <div class="d-flex justify-end">
                <v-btn color="primary" type="submit">Login</v-btn>
                <v-btn text to="/account/register">Register</v-btn>
              </div>

            </v-form>
          </ValidationObserver>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="8" xl="4" md="6" sm="8" style="visibility: hidden">
          <v-img :src="images.logo" class="mb-5 mx-auto form-logo"></v-img>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import logoImage from '@/assets/logo2.jpg'
import User from "@/models/User";

export default {
  name: 'LoginPage',

  data() {
    return {
      images: {
        logo: logoImage
      },
      form: {
        email: '',
        password: '',
      },
      drawer: false,
    }
  },

  methods: {
    async onSubmit() {
      try {
        await User.api().login({email: this.form.email, password: this.form.password})
        await this.$router.push('/dashboard')
      } catch (error) {
        if (400 <= error.response?.statusCode < 500){
          this.$refs.form.setErrors(error.response.data)
        } else {
          throw error
        }
      }

    }
  }
}
</script>
