var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"8","xl":"4","md":"6","sm":"8"}},[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{staticClass:"mt-5",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('ValidationProvider',{attrs:{"name":"detail"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(errors.length > 0)?[_c('v-alert',{attrs:{"border":"left","colored-border":"","type":"warning","elevation":"2"}},_vm._l((errors),function(error,index){return _c('span',{key:index},[_vm._v(_vm._s(error))])}),0)]:_vm._e()]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Email","name":"email","prepend-icon":"mdi-email","type":"text","error-messages":errors,"outlined":""},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"id":"password","label":"Password","name":"password","prepend-icon":"mdi-lock","type":"password","outlined":""},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})]}}],null,true)}),_c('div',{staticClass:"d-flex justify-end"},[_c('v-btn',{attrs:{"color":"primary","type":"submit"}},[_vm._v("Login")]),_c('v-btn',{attrs:{"text":"","to":"/account/register"}},[_vm._v("Register")])],1)],1)]}}])})],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticStyle:{"visibility":"hidden"},attrs:{"cols":"8","xl":"4","md":"6","sm":"8"}},[_c('v-img',{staticClass:"mb-5 mx-auto form-logo",attrs:{"src":_vm.images.logo}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }